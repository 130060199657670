import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
   providedIn: 'root'
})
export class LoaderService {
   isLoading = new Subject<boolean>();
   spinnerLoader = new BehaviorSubject(false);

   constructor() {}

   setSpinner(status: boolean) {
      if (status) {
         document.body.style.overflow = 'hidden';
      } else {
         document.body.style.overflow = 'auto';
      }
      this.spinnerLoader.next(status);
   }

   getSpinner() {
      return this.spinnerLoader.asObservable();
   }

   show(req) {
      if (req.method !== 'GET') {
         this.isLoading.next(true);
         const running = document.querySelector('.primary__button');
         if (running) {
            running.classList.add('running');
         }
      }
   }

   hide(req) {
      if (req.method !== 'GET') {
         this.isLoading.next(false);
         const running = document.querySelector('.primary__button');
         if (running) {
            running.classList.remove('running');
         }
      }
   }
}
