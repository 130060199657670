import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { LoaderService } from './loader.service';

@Injectable({
   providedIn: 'root'
})
export class OrderService {
   rootUrl = environment.apiUrl;

   constructor(
      private http: HttpClient,
      private loaderService: LoaderService
   ) {}

   donwloadZip(key: string, user) {
      this.loaderService.setSpinner(true);
      let _this = this;
      this.http
         .get(this.rootUrl + '/download/' + key, {
            responseType: 'arraybuffer'
         })
         .subscribe(function (response) {
            const blob = new Blob([response], { type: 'application/zip' });

            const a: any = document.createElement('a');
            document.body.appendChild(a);
            _this.loaderService.setSpinner(false);

            a.style = 'display: none';
            const url = window.URL.createObjectURL(blob);
            a.download = `${user.name}.zip`;
            a.href = url;
            a.click();
            window.URL.revokeObjectURL(url);
         });
   }
}
