import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/api/custom-services/loader.service';

@Component({
   selector: 'app-admin-layout',
   templateUrl: './admin-layout.component.html',
   styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {
   loading;
   constructor(private loaderServier: LoaderService) {}

   ngOnInit() {
      this.loaderServier.getSpinner().subscribe(loader => {
         this.loading = loader;
      });
   }
}
