import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { OrdersComponent } from './pages/orders/orders.component';
import { TokenInterceptor } from './api/interceptors/token.interceptor';
import { OrderDetailComponent } from './pages/orders/order-detail/order-detail.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
   imports: [
      BrowserAnimationsModule,
      FormsModule,
      HttpClientModule,
      ComponentsModule,
      NgbModule,
      RouterModule,
      AppRoutingModule,
      MatTabsModule,
      MatCheckboxModule,
      MatProgressSpinnerModule
   ],
   declarations: [
      AppComponent,
      AdminLayoutComponent,
      AuthLayoutComponent,
      OrdersComponent,
      OrderDetailComponent
   ],
   providers: [
      {
         provide: HTTP_INTERCEPTORS,
         useClass: TokenInterceptor,
         multi: true
      }
   ],
   bootstrap: [AppComponent]
})
export class AppModule {}
