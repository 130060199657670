import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {switchMap} from 'rxjs/operators';
import {InvoicesService} from '../../../api/services/invoices.service';
import {environment} from '../../../../environments/environment';
import {cityList} from '../../../api/enum/city.enum';
import {OrderService} from '../../../api/custom-services/order.service';
import {countriesList} from '../../../../../../photoorder/src/app/api/enum/countries.enum';

@Component({
    selector: 'app-order-detail',
    templateUrl: './order-detail.component.html',
    styleUrls: ['./order-detail.component.css']
})
export class OrderDetailComponent implements OnInit {
    invoiceDetails: any;
    public url: string;
    private cityList = cityList;
    imagesOreder  = [];
    cityName: string;
    countryName: string;

    constructor(private route: ActivatedRoute, private invoicesService: InvoicesService, private orderService: OrderService) {
        this.url = environment.url;
    }

    ngOnInit(): void {
        this.route.params.pipe(switchMap(params => {
                return this.invoicesService.getInvoiceById(params.id);
            })
        ).subscribe((response) => {
            this.invoiceDetails = response;
            this.cityName = this.cityList.find(city => city.id === this.invoiceDetails.address.city_id).name;
            this.countryName = countriesList.find(country => country.id === this.invoiceDetails.address.country_id).name;
        });
    }

    trackByFn() {
        return Math.random().toString(36).substring(7);
    }

    filterImage(id: string) {
        this.imagesOreder = [];
        this.invoiceDetails.images.forEach(item => {
            if (item.order_id === id) {
                this.imagesOreder.push(item);
            }
        });
        return this.imagesOreder;
    }

    downloadFolder(folder: string) {
        this.orderService.donwloadZip(folder, this.invoiceDetails.user);
    }
}
