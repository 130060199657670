/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { InvoiceDashboard } from '../models/invoice-dashboard';
import { ResponseGenearl } from '../models/response-genearl';
import { Invoice } from '../models/invoice';

/**
 * Operations about user
 */
@Injectable({
  providedIn: 'root',
})
class InvoicesService extends __BaseService {
  static readonly getInvoicesPath = '/invoices/{status}/{page}';
  static readonly getInvoiceByIdPath = '/invoice/{id}';
  static readonly completedOrderPath = '/invoice/{id}';
  static readonly getUserInvoicesPath = '/user/invoices';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * This function to update order
   * @param params The `InvoicesService.GetInvoicesParams` containing the following parameters:
   *
   * - `status`:
   *
   * - `page`:
   */
  getInvoicesResponse(params: InvoicesService.GetInvoicesParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/invoices/${encodeURIComponent(params.status)}/${encodeURIComponent(params.page)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * This function to update order
   * @param params The `InvoicesService.GetInvoicesParams` containing the following parameters:
   *
   * - `status`:
   *
   * - `page`:
   */
  getInvoices(params: InvoicesService.GetInvoicesParams): __Observable<null> {
    return this.getInvoicesResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * This function to update order
   * @param id null
   * @return User info
   */
  getInvoiceByIdResponse(id?: string): __Observable<__StrictHttpResponse<InvoiceDashboard>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/invoice/${encodeURIComponent(id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<InvoiceDashboard>;
      })
    );
  }
  /**
   * This function to update order
   * @param id null
   * @return User info
   */
  getInvoiceById(id?: string): __Observable<InvoiceDashboard> {
    return this.getInvoiceByIdResponse(id).pipe(
      __map(_r => _r.body as InvoiceDashboard)
    );
  }

  /**
   * This function will complete the order and will delete the images
   * @param id null
   * @return User info
   */
  completedOrderResponse(id?: string): __Observable<__StrictHttpResponse<ResponseGenearl>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/invoice/${encodeURIComponent(id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseGenearl>;
      })
    );
  }
  /**
   * This function will complete the order and will delete the images
   * @param id null
   * @return User info
   */
  completedOrder(id?: string): __Observable<ResponseGenearl> {
    return this.completedOrderResponse(id).pipe(
      __map(_r => _r.body as ResponseGenearl)
    );
  }

  /**
   * This function to update order
   * @return User info
   */
  getUserInvoicesResponse(): __Observable<__StrictHttpResponse<Array<Invoice>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/user/invoices`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Invoice>>;
      })
    );
  }
  /**
   * This function to update order
   * @return User info
   */
  getUserInvoices(): __Observable<Array<Invoice>> {
    return this.getUserInvoicesResponse().pipe(
      __map(_r => _r.body as Array<Invoice>)
    );
  }
}

module InvoicesService {

  /**
   * Parameters for getInvoices
   */
  export interface GetInvoicesParams {
    status?: number;
    page?: number;
  }
}

export { InvoicesService }
