export const countriesList = [
    {
        id: 1,
        name: 'Kosovo'
    },
    {
        id: 2,
        name: 'Albania'
    },
    {
        id: 3,
        name: 'Macedonia'
    }
];
