import {Component, OnInit} from '@angular/core';
import {InvoicesService} from '../../api/services/invoices.service';
import {Router} from '@angular/router';
import {InvoiceDashboard} from '../../api/models/invoice-dashboard';

@Component({
    selector: 'app-orders',
    templateUrl: './orders.component.html',
    styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {

    invoices = [];
    maxPage;
    pageList;
    currentPage = 1;
    invoicesId = [];
    status = 1;
    public clicked: boolean = true;
    public clicked1: boolean = false;

    constructor(private invoicesService: InvoicesService, private route: Router) {
    }

    ngOnInit() {
        this.getOreder();
    }

    getOreder(){
        const params = {status: this.status, page: 1};
        this.invoicesService.getInvoices(params).subscribe((result: InvoiceDashboard) => {
            result.invoices.map(item => {
                item.checked = false;
                this.invoices.push(item);
            });
            this.maxPage = Math.ceil(result.count / 15);
            this.pageList = this.getArrayOfPage(this.maxPage);
        });
    }

    trackByFn() {
        return Math.random().toString(36).substring(7);
    }

    orderDetails(id: string) {
        this.route.navigate([`/order/${id}`]);
    }

    changePage(page: number) {
        this.currentPage = page;
        const params = {status: this.status, page: page};
        this.invoicesService.getInvoices(params).subscribe((result: InvoiceDashboard) => {
            this.invoices = result.invoices;
        });
    }


    private getArrayOfPage(lastPage: number): number [] {
        const pageArray: number [] = [];
        for (let i = 1; i <= lastPage; i++) {
            pageArray.push(i);
        }
        return pageArray;
    }

    changeStatus(id: string, $e) {
        this.invoicesId.push(id);
    }

    completeInvoice(item, index) {
        this.invoicesService.completedOrder(item._id).toPromise().then(result => {
            if (result) {
                this.invoices[index].status = result.status;
            }
        }).catch(e => {

        })
    }

    filterOrder(state) {
        this.invoices = [];
        this.status = state == 'pending' ? 1 : 3;
        this.getOreder();
    }
}
