export const cityList = [
    {
        id: 1,
        name: 'Prishtinë',
        country_id: 1
    },
    {
        id: 2,
        name: 'Prizren',
        country_id: 1
    },
    {
        id: 3,
        name: 'Gjilan',
        country_id: 1
    },
    {
        id: 4,
        name: 'Pejë',
        country_id: 1
    },
    {
        id: 5,
        name: 'Mitrovicë',
        country_id: 1
    },
    {
        id: 6,
        name: 'Ferizaj',
        country_id: 1
    },
    {
        id: 7,
        name: 'Gjakovë',
        country_id: 1
    },
    {
        id: 8,
        name: 'Vushtrri',
        country_id: 1
    },
    {
        id: 9,
        name: 'Podujevë',
        country_id: 1
    },
    {
        id: 10,
        name: 'Fushë Kosovë',
        country_id: 1
    },
    {
        id: 11,
        name: 'Rahovec',
        country_id: 1
    },
    {
        id: 12,
        name: 'Suharekë',
        country_id: 1
    },
    {
        id: 13,
        name: 'Kaçanik',
        country_id: 1
    },
    {
        id: 14,
        name: 'Skenderaj',
        country_id: 1
    },
    {
        id: 15,
        name: 'Kamenicë',
        country_id: 1
    },
    {
        id: 16,
        name: 'Shtime',
        country_id: 1
    },
    {
        id: 17,
        name: 'Lipjan',
        country_id: 1
    },
    {
        id: 18,
        name: 'Obiliq',
        country_id: 1
    },
    {
        id: 19,
        name: 'Drenas',
        country_id: 1
    },
    {
        id: 20,
        name: 'Junik',
        country_id: 1
    },
    {
        id: 21,
        name: 'Klinë',
        country_id: 1
    },
    {
        id: 22,
        name: 'Mamushë',
        country_id: 1
    },
    {
        id: 23,
        name: 'Istog',
        country_id: 1
    },
    {
        id: 24,
        name: 'Viti',
        country_id: 1
    },
    {
        id: 25,
        name: 'Deçan',
        country_id: 1
    },
    {
        id: 26,
        name: 'Leposaviq',
        country_id: 1
    },
    {
        id: 27,
        name: 'Malishevë',
        country_id: 1
    },
    {
        id: 28,
        name: 'Graçanicë',
        country_id: 1
    },
    {
        id: 29,
        name: 'Han i Elezit',
        country_id: 1
    },
    {
        id: 30,
        name: 'Zubin Potok',
        country_id: 1
    },
    {
        id: 31,
        name: 'Zveçan',
        country_id: 1
    },
    {
        id: 32,
        name: 'Zveçan',
        country_id: 1
    },
    {
        id: 33,
        name: 'Shtërpcë',
        country_id: 1
    },
    {
        id: 34,
        name: 'Dragash',
        country_id: 1
    },
    {
        id: 35,
        name: 'Kllokot',
        country_id: 1
    },
    {
        id: 36,
        name: 'Ranillug',
        country_id: 1
    },
    {
        id: 37,
        name: 'Partesh',
        country_id: 1
    },
    {
        id: 38,
        name: 'Novobërdë',
        country_id: 1
    },
    {
        name: 'Tirana',
        id: 39,
        country_id: 2
    },
    {
        name: 'Durrës',
        id: 40,
        country_id: 2
    },
    {
        name: 'Vlorë',
        id: 41,
        country_id: 2
    },
    {
        name: 'Kamëz',
        id: 42,
        country_id: 2
    },
    {
        name: 'Fier',
        id: 43,
        country_id: 2
    },
    {
        name: 'Shkodër',
        id: 44,
        country_id: 2
    },
    {
        name: 'Elbasan',
        id: 45,
        country_id: 2
    },
    {
        name: 'Korçë',
        id: 46,
        country_id: 2
    },
    {
        name: 'Sarandë',
        id: 47,
        country_id: 2
    },
    {
        name: 'Berat',
        id: 48,
        country_id: 2
    },
    {
        name: 'Lushnjë',
        id: 49,
        country_id: 2
    },
    {
        name: 'Kavajë',
        id: 50,
        country_id: 2
    },
    {
        name: 'Gjirokastër',
        id: 51,
        country_id: 2
    },
    {
        name: 'Pogradec',
        id: 52,
        country_id: 2
    },
    {
        name: 'Fushë-Krujë',
        id: 53,
        country_id: 2
    },
    {
        name: 'Laç',
        id: 54,
        country_id: 2
    },
    {
        name: 'Kukës',
        id: 55,
        country_id: 2
    },
    {
        name: 'Sukth',
        id: 56,
        country_id: 2
    },
    {
        name: 'Lezhë',
        id: 57,
        country_id: 2
    },
    {
        name: 'Patos',
        id: 58,
        country_id: 2
    },
    {
        name: 'Peshkopi',
        id: 59,
        country_id: 2
    },
    {
        name: 'Kuçovë',
        id: 60,
        country_id: 2
    },
    {
        name: 'Krujë',
        id: 61,
        country_id: 2
    },
    {
        name: 'Vorë',
        id: 62,
        country_id: 2
    },
    {
        name: 'Burrel',
        id: 63,
        country_id: 2
    },
    {
        name: 'Rrëshen',
        id: 64,
        country_id: 2
    },
    {
        name: 'Belsh',
        id: 65,
        country_id: 2
    },
    {
        name: 'Divjakë',
        id: 66,
        country_id: 2
    },
    {
        name: 'Gramsh',
        id: 67,
        country_id: 2
    },
    {
        name: 'Mamurras',
        id: 68,
        country_id: 2
    },
    {
        name: 'Bulqizë',
        id: 69,
        country_id: 2
    },
    {
        name: 'Vau i Dejës',
        id: 70,
        country_id: 2
    },
    {
        name: 'Shëngjin',
        id: 71,
        country_id: 2
    },
    {
        name: 'Ballsh',
        id: 72,
        country_id: 2
    },
    {
        name: 'Shijak',
        id: 73,
        country_id: 2
    },
    {
        name: 'Bilisht',
        id: 74,
        country_id: 2
    },
    {
        name: 'Rrogozhinë',
        id: 75,
        country_id: 2
    },
    {
        name: 'Librazhd',
        id: 76,
        country_id: 2
    },
    {
        name: 'Cërrik',
        id: 77,
        country_id: 2
    },
    {
        name: 'Roskovec',
        id: 78,
        country_id: 2
    },
    {
        name: 'Peqin',
        id: 79,
        country_id: 2
    },
    {
        name: 'Krumë',
        id: 80,
        country_id: 2
    },
    {
        name: 'Përmet',
        id: 81,
        country_id: 2
    },
    {
        name: 'Përrenjas',
        id: 82,
        country_id: 2
    },
    {
        name: 'Delvinë',
        id: 83,
        country_id: 2
    },
    {
        name: 'Bajram Curri',
        id: 84,
        country_id: 2
    },
    {
        name: 'Rubik',
        id: 85,
        country_id: 2
    },
    {
        name: 'Tepelenë',
        id: 86,
        country_id: 2
    },
    {
        name: 'Poliçan',
        id: 87,
        country_id: 2
    },
    {
        name: 'Maliq',
        id: 88,
        country_id: 2
    },
    {
        name: 'Çorovodë',
        id: 89,
        country_id: 2
    },
    {
        name: 'Ersekë',
        id: 90,
        country_id: 2
    },
    {
        name: 'Koplik',
        id: 91,
        country_id: 2
    },
    {
        name: 'Pukë',
        id: 92,
        country_id: 2
    },
    {
        name: 'Skopje',
        id: 92,
        country_id: 3
    },
    {
        name: 'Tetovo',
        id: 93,
        country_id: 3
    },
    {
        name: 'Bitola',
        id: 94,
        country_id: 3
    },
    {
        name: 'Kumanovo',
        id: 95,
        country_id: 3
    },
    {
        name: 'Prilep',
        id: 96,
        country_id: 3
    },
    {
        name: 'Ohrid',
        id: 97,
        country_id: 3
    },
    {
        name: 'Strumica',
        id: 98,
        country_id: 3
    },
    {
        name: 'Veles',
        id: 99,
        country_id: 3
    },
    {
        name: 'Štip',
        id: 100,
        country_id: 3
    },
    {
        name: 'Kavadarci',
        id: 101,
        country_id: 3
    },
    {
        name: 'Gostivar',
        id: 102,
        country_id: 3
    },
    {
        name: 'Kočani',
        id: 103,
        country_id: 3
    },
    {
        name: 'Kičevo',
        id: 104,
        country_id: 3
    },
    {
        name: 'Struga',
        id: 105,
        country_id: 3
    },
    {
        name: 'Radoviš',
        id: 106,
        country_id: 3
    },
    {
        name: 'Gevgelija',
        id: 107,
        country_id: 3
    },
    {
        name: 'Debar',
        id: 108,
        country_id: 3
    },
    {
        name: 'Kriva Palanka',
        id: 109,
        country_id: 3
    },
    {
        name: 'Sveti Nikole',
        id: 110,
        country_id: 3
    },
    {
        name: 'Negotino',
        id: 111,
        country_id: 3
    },
    {
        name: 'Delčevo',
        id: 112,
        country_id: 3
    },
    {
        name: 'Vinica',
        id: 113,
        country_id: 3
    },
    {
        name: 'Berovo',
        id: 114,
        country_id: 3
    },
    {
        name: 'Kratovo',
        id: 115,
        country_id: 3
    },
    {
        name: 'Bogdanci',
        id: 116,
        country_id: 3
    },
    {
        name: 'Kruševo',
        id: 117,
        country_id: 3
    },
    {
        name: 'Valandovo',
        id: 118,
        country_id: 3
    },
    {
        name: 'Makedonski Brod',
        id: 119,
        country_id: 3
    },
    {
        name: 'Pehčevo',
        id: 120,
        country_id: 3
    },
    {
        name: 'Probištip',
        id: 121,
        country_id: 3
    },
    {
        name: 'Novo Selo',
        id: 122,
        country_id: 3
    },
    {
        name: 'Demir Hisar',
        id: 123,
        country_id: 3
    },
    {
        name: 'Demir Kapija',
        id: 124,
        country_id: 3
    },
    {
        name: 'Sopište',
        id: 125,
        country_id: 3
    },
    {
        name: 'Aračinovo',
        id: 126,
        country_id: 3
    },
    {
        name: 'Brvenica',
        id: 127,
        country_id: 3
    },
    {
        name: 'Bogovinje',
        id: 128,
        country_id: 3
    },
    {
        name: 'Vrapčište',
        id: 129,
        country_id: 3
    },
    {
        name: 'Ilinden',
        id: 130,
        country_id: 3
    },
    {
        name: 'Petrovec',
        id: 131,
        country_id: 3
    },
    {
        name: 'Studeničani',
        id: 132,
        country_id: 3
    },
    {
        name: 'Tearce',
        id: 133,
        country_id: 3
    },
    {
        name: 'Jegunovce',
        id: 134,
        country_id: 3
    },
    {
        name: 'Želino',
        id: 135,
        country_id: 3
    },
    {
        name: 'Lipkovo',
        id: 136,
        country_id: 3
    },
    {
        name: 'Obleševo',
        id: 137,
        country_id: 3
    },
    {
        name: 'Bosilovo',
        id: 138,
        country_id: 3
    },
    {
        name: 'Vevčani',
        id: 139,
        country_id: 3
    },
    {
        name: 'Plasnica',
        id: 140,
        country_id: 3
    },
    {
        name: 'Krivogaštani',
        id: 141,
        country_id: 3
    },
    {
        name: 'Vasilevo',
        id: 142,
        country_id: 3
    },
    {
        name: 'Centar Župa',
        id: 143,
        country_id: 3
    },
    {
        name: 'Zrnovci',
        id: 144,
        country_id: 3
    },
    {
        name: 'Čučer-Sandevo',
        id: 145,
        country_id: 3
    },
    {
        name: 'Makedonska Kamenica',
        id: 146,
        country_id: 3
    },
    {
        name: 'Dolneni',
        id: 147,
        country_id: 3
    },
    {
        name: 'Rosoman',
        id: 148,
        country_id: 3
    },
    {
        name: 'Zelenikovo',
        id: 149,
        country_id: 3
    },
    {
        name: 'Resen',
        id: 150,
        country_id: 3
    },
    {
        name: 'Mogila',
        id: 151,
        country_id: 3
    },
    {
        name: 'Karbinci',
        id: 152,
        country_id: 3
    },
    {
        name: 'Konče',
        id: 153,
        country_id: 3
    },
    {
        name: 'Rankovce',
        id: 154,
        country_id: 3
    },
    {
        name: 'Lozovo',
        id: 155,
        country_id: 3
    },
    {
        name: 'Rostuša',
        id: 156,
        country_id: 3
    },
    {
        name: 'Gradsko',
        id: 157,
        country_id: 3
    },
    {
        name: 'Belčišta',
        id: 158,
        country_id: 3
    },
    {
        name: 'Staro Nagoričane',
        id: 159,
        country_id: 3
    },
    {
        name: 'Novaci',
        id: 160,
        country_id: 3
    },
    {
        name: 'Star Dojran',
        id: 161,
        country_id: 3
    }
];
